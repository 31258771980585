
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import ActiveCustomersNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/ActiveCustomersNumberDisplayer.vue';
import ExistingCustomersNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/ExistingCustomersNumberDisplayer.vue';
import NewCustomersNumberDisplayer from '@/components/reports-v2/components/codedWidgets/sales/NewCustomersNumberDisplayer.vue';
import CustomerGrowthPieChart from '@/components/reports-v2/components/codedWidgets/sales/CustomerGrowthPieChart.vue';
import NewCustomerTable from '@/components/reports-v2/components/codedWidgets/sales/NewCustomerTable.vue';
import CustomerRetentionTable from '@/components/reports-v2/components/codedWidgets/sales/CustomerRetentionTable.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		CustomerGrowthPieChart,
		ActiveCustomersNumberDisplayer,
		ExistingCustomersNumberDisplayer,
		NewCustomersNumberDisplayer,
		NewCustomerTable,
		CustomerRetentionTable,
		StickyFilterContainer,
		DateFilter,
		LastSync,
	},
})
export default class SalesGrowth extends Vue {
	public selectedDay: number = moment().endOf('year').valueOf();

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('year').valueOf();
	}

	public get selectedDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedCustomers() {
		return gdbx.allCustomers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
