
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilterable from '@/components/reports-v2/components/filterables/BaseFilterable.vue';
import BasicPieChart from '@/components/reports-v2/components/elements/charts/BasicPieChart.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import * as echarts from 'echarts';
import { TooltipFormatter } from '../../elements/charts/helpers/tooltipFormatter';
import { LabelFormatter } from '../../elements/charts/helpers/labelFormatter';
import { DataViewFormatter } from '../../elements/charts/helpers/dataViewFormatter';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import moment from 'moment';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';

interface PieData {
	existingSalesAmount: number;
	newSalesAmount: number;
	existingcustomerCount: number;
	newCustomerCount: number;
}

@Component({
	components: {
		BasicPieChart,
		BaseFilterable,
		DateFilter,
		DataInfo,
	},
})
export default class CustomerGrowthPieChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public selectedYear1: number = moment(this.selectedAsOfDate)
		.endOf('year')
		.valueOf();
	public selectedYear2: number = moment(this.selectedAsOfDate)
		.add(-1, 'year')
		.endOf('year')
		.valueOf();
	public selectedYear3: number = moment(this.selectedAsOfDate)
		.add(-2, 'year')
		.endOf('year')
		.valueOf();

	public year1Details: PieData[] = [
		{
			existingSalesAmount: 0,
			newSalesAmount: 0,
			existingcustomerCount: 0,
			newCustomerCount: 0,
		},
	];
	public year2Details: PieData[] = [
		{
			existingSalesAmount: 0,
			newSalesAmount: 0,
			existingcustomerCount: 0,
			newCustomerCount: 0,
		},
	];
	public year3Details: PieData[] = [
		{
			existingSalesAmount: 0,
			newSalesAmount: 0,
			existingcustomerCount: 0,
			newCustomerCount: 0,
		},
	];

	public seriesYear1Amount: echarts.ECharts[] | any = [];
	public seriesYear2Amount: echarts.ECharts[] | any = [];
	public seriesYear3Amount: echarts.ECharts[] | any = [];
	public seriesYear1Count: echarts.ECharts[] | any = [];
	public seriesYear2Count: echarts.ECharts[] | any = [];
	public seriesYear3Count: echarts.ECharts[] | any = [];

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get labelFormatter() {
		return LabelFormatter.pieChart;
	}

	public get labelFormatterNoCurrencyDecimal() {
		return LabelFormatter.pieChartWithoutCurrencyDecimal;
	}

	public get tooltipFormatter() {
		return TooltipFormatter.pieChart;
	}

	public get tooltipFormatterNoCurrencyDecimal() {
		return TooltipFormatter.pieChartNoCurrencyDecimal;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.pieChart;
	}

	public get dataViewFormatterNoCurrencyNoDecimal() {
		return DataViewFormatter.pieChartWithoutCurrencyDecimal;
	}

	public get year1() {
		return moment(this.selectedAsOfDate).year();
	}

	public get year2() {
		return moment(this.selectedYear2).year();
	}

	public get year2Range() {
		return [
			moment(this.selectedYear2).startOf('year').valueOf(),
			moment(this.selectedYear2).endOf('year').valueOf(),
		];
	}

	public get year3() {
		return moment(this.selectedYear3).year();
	}

	public get year3Range() {
		return [
			moment(this.selectedYear3).startOf('year').valueOf(),
			moment(this.selectedYear3).endOf('year').valueOf(),
		];
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAgents,
			selectedCustomers,
			year1,
			year2,
			year3,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedCustomers,
			year1,
			year2,
			year3,
		]);
	}

	public async expensiveCalc() {
		const ivdnYear1Ref = FilteredDatabase.ref('customerTrades').year(
			this.year1,
		);
		const ivdnYear2Ref = FilteredDatabase.ref('customerTrades').year(
			this.year2,
		);
		const ivdnYear3Ref = FilteredDatabase.ref('customerTrades').year(
			this.year3,
		);

		const year1CustomerList = [
			...(await (await ivdnYear1Ref.get()).getSet('code')),
		];

		const year2CustomerList = [
			...(await (await ivdnYear2Ref.get()).getSet('code')),
		];

		const year3CustomerList = [
			...(await (await ivdnYear3Ref.get()).getSet('code')),
		];

		// Existing //

		const year1ExistingRef = FilteredDatabase.ref('globalCustomers')
			.customers(year1CustomerList)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.selectedDateRange[0]],
			]);

		const year2ExistingRef = FilteredDatabase.ref('globalCustomers')
			.customers(year2CustomerList)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.year2Range[0]],
			]);

		const year3ExistingRef = FilteredDatabase.ref('globalCustomers')
			.customers(year3CustomerList)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.year3Range[0]],
			]);

		// New //

		const year1NewRef = FilteredDatabase.ref('globalCustomers')
			.customers(year1CustomerList)
			.numberRange('firstDeal', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		const year2NewRef = FilteredDatabase.ref('globalCustomers')
			.customers(year2CustomerList)
			.numberRange('firstDeal', [
				['>=', this.year2Range[0]],
				['<=', this.year2Range[1]],
			]);

		const year3NewRef = FilteredDatabase.ref('globalCustomers')
			.customers(year3CustomerList)
			.numberRange('firstDeal', [
				['>=', this.year3Range[0]],
				['<=', this.year3Range[1]],
			]);

		const year1ExistingCustomerList = [
			...(await (await year1ExistingRef.get()).getSet('code')),
		];

		const year2ExistingCustomerList = [
			...(await (await year2ExistingRef.get()).getSet('code')),
		];

		const year3ExistingCustomerList = [
			...(await (await year3ExistingRef.get()).getSet('code')),
		];

		const year1NewCustomerList = [
			...(await (await year1NewRef.get()).getSet('code')),
		];

		this.year1Details[0].newCustomerCount = year1NewCustomerList.length;

		const year2NewCustomerList = [
			...(await (await year2NewRef.get()).getSet('code')),
		];

		this.year2Details[0].newCustomerCount = year2NewCustomerList.length;

		const year3NewCustomerList = [
			...(await (await year3NewRef.get()).getSet('code')),
		];

		this.year3Details[0].newCustomerCount = year3NewCustomerList.length;

		// Trade

		const year1ExistingTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year1ExistingCustomerList)
			.year(this.year1);

		const year2ExistingTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year2ExistingCustomerList)
			.year(this.year2);

		const year3ExistingTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year3ExistingCustomerList)
			.year(this.year3);

		const year1NewTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year1NewCustomerList)
			.year(this.year1);

		const year2NewTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year2NewCustomerList)
			.year(this.year2);

		const year3NewTradeRef = FilteredDatabase.ref('customerTrades')
			.customers(year3NewCustomerList)
			.year(this.year3);

		const year1ExistingTradeRefHead = await year1ExistingTradeRef
			.clone()
			.includes('docType', ['IV', 'DN'])
			.get();

		const year1ExistingIvdn = await year1ExistingTradeRefHead.getSum('amount');

		this.year1Details[0].existingcustomerCount = [
			...(await (await year1ExistingTradeRefHead).getSet('code')),
		].length;

		const year1ExistingCn = await (
			await year1ExistingTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year1Details[0].existingSalesAmount =
			year1ExistingIvdn - year1ExistingCn;

		const year2ExistingTradeRefHead = await year2ExistingTradeRef
			.clone()
			.includes('docType', ['IV', 'DN'])
			.get();

		const year2ExistingIvdn = await year2ExistingTradeRefHead.getSum('amount');

		this.year2Details[0].existingcustomerCount = [
			...(await (await year2ExistingTradeRefHead).getSet('code')),
		].length;

		const year2ExistingCn = await (
			await year2ExistingTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year2Details[0].existingSalesAmount =
			year2ExistingIvdn - year2ExistingCn;

		const year3ExistingTradeRefHead = await year3ExistingTradeRef
			.clone()
			.includes('docType', ['IV', 'DN'])
			.get();

		const year3ExistingIvdn = await year3ExistingTradeRefHead.getSum('amount');

		this.year3Details[0].existingcustomerCount = [
			...(await (await year3ExistingTradeRefHead).getSet('code')),
		].length;

		const year3ExistingCn = await (
			await year3ExistingTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year3Details[0].existingSalesAmount =
			year3ExistingIvdn - year3ExistingCn;

		const year1NewIvdn = await (
			await year1NewTradeRef.clone().includes('docType', ['IV', 'DN']).get()
		).getSum('amount');

		const year1NewCn = await (
			await year1NewTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year1Details[0].newSalesAmount = year1NewIvdn - year1NewCn;

		const year2NewIvdn = await (
			await year2NewTradeRef.clone().includes('docType', ['IV', 'DN']).get()
		).getSum('amount');

		const year2NewCn = await (
			await year2NewTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year2Details[0].newSalesAmount = year2NewIvdn - year2NewCn;

		const year3NewIvdn = await (
			await year3NewTradeRef.clone().includes('docType', ['IV', 'DN']).get()
		).getSum('amount');

		const year3NewCn = await (
			await year3NewTradeRef.clone().includes('docType', ['CN']).get()
		).getSum('amount');

		this.year3Details[0].newSalesAmount = year3NewIvdn - year3NewCn;

		this.seriesYear1Amount = {
			name: this.year1,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatter,
			},
			data: [
				{
					name: 'Existing',
					value: this.year1Details[0].existingSalesAmount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year1Details[0].newSalesAmount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.seriesYear1Count = {
			name: this.year1,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatterNoCurrencyDecimal,
			},
			data: [
				{
					name: 'Existing',
					value: this.year1Details[0].existingcustomerCount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year1Details[0].newCustomerCount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.seriesYear2Amount = {
			name: this.year2,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatter,
			},
			data: [
				{
					name: 'Existing',
					value: this.year2Details[0].existingSalesAmount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year2Details[0].newSalesAmount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.seriesYear2Count = {
			name: this.year2,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatterNoCurrencyDecimal,
			},
			data: [
				{
					name: 'Existing',
					value: this.year2Details[0].existingcustomerCount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year2Details[0].newCustomerCount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.seriesYear3Amount = {
			name: this.year3,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatter,
			},
			data: [
				{
					name: 'Existing',
					value: this.year3Details[0].existingSalesAmount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year3Details[0].newSalesAmount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.seriesYear3Count = {
			name: this.year3,
			type: 'pie',
			radius: ['50%', '70%'],
			label: {
				show: true,
				position: 'inner',
				formatter: this.labelFormatterNoCurrencyDecimal,
			},
			data: [
				{
					name: 'Existing',
					value: this.year3Details[0].existingcustomerCount,
					itemStyle: { color: GobiColor.TEAL },
				},
				{
					name: 'New',
					value: this.year3Details[0].newCustomerCount,
					itemStyle: { color: GobiColor.ORANGE },
				},
			],
		};

		this.saveHistory(
			'seriesYear1Amount',
			'seriesYear2Amount',
			'seriesYear3Amount',
			'seriesYear1Count',
			'seriesYear2Count',
			'seriesYear3Count',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
