
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { addComma, roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: {
		AmountDisplayerFilterable,
		IncludeCreditNoteToggle,
	},
})
export default class ExistingCustomersNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public customersCount = 0;
	public totalSales: any = '';
	public salesPerCustomer: any = '';

	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			currentYear,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('customerTrades').dateRange(
			this.selectedDateRange,
		);

		const globalCustomerList = [
			...(await (await tradeRef.get()).getSet('code')),
		];

		const globalRef = FilteredDatabase.ref('globalCustomers')
			.customers(globalCustomerList)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<', this.selectedDateRange[0]],
			]);

		const existingCustomerList = [
			...(await (await globalRef.get()).getSet('code')),
		];

		const tradeRef2 = FilteredDatabase.ref('customerTrades')
			.dateRange(this.selectedDateRange)
			.customers(existingCustomerList);

		const tradeIvDnRef = tradeRef2.clone().includes('docType', ['IV', 'DN']);
		const ivDn = await (await tradeIvDnRef.get()).getSum('amount');

		this.customersCount = [
			...(await (await tradeIvDnRef.get()).getSet('code')),
		].length;

		const tradeCNRef = tradeRef2.clone().includes('docType', ['CN']);
		const cn = await (await tradeCNRef.get()).getSum('amount');

		const sales = ivDn - (this.includeCreditNote ? cn : 0);
		this.totalSales = addComma(sales, this.decimal, true);

		this.salesPerCustomer = addComma(
			sales / this.customersCount,
			this.decimal,
			true,
		);

		this.saveHistory('customersCount', 'totalSales', 'salesPerCustomer');
	}
}
