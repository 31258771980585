
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerSupplierTradeCard,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
	components: { TableFilterable, SelectFilter, IncludeCreditNoteToggle },
})
export default class NewCustomerTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales', 'customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public selectedMonth: number = moment().month();

	public get monthList() {
		const list: ValueText[] = [];
		for (let i = 0; i < 12; i++) {
			const object: ValueText = { value: i, text: '' };
			object.text = moment().month(i).format('MMM');
			list.push(object);
		}
		return list;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get exportFileName() {
		const formattedDate = [
			moment(this.currentMonthRange[0]).format('DD MMM YY'),
			moment(this.currentMonthRange[1]).format('DD MMM YY'),
		];
		return 'New Customer' + '_' + formattedDate[0] + ' to ' + formattedDate[1];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonth() {
		return this.selectedMonth;
	}

	public get currentMonthRange(): [number, number] {
		return [
			moment([this.currentYear, this.currentMonth]).startOf('month').valueOf(),
			moment([this.currentYear, this.currentMonth]).endOf('month').valueOf(),
		];
	}

	public get formattedMonth() {
		return moment([this.currentYear, this.currentMonth]).format('MMM');
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			currentMonth,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			currentMonth,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const globalRef = FilteredDatabase.ref('globalCustomers').dateRange(
			this.currentMonthRange,
			'firstDeal',
		);

		const globalCards: CustomerSupplierTradeCard[] = await (
			await globalRef.get()
		).getCards();

		const customerAgentList = {};

		for (const global of globalCards) {
			if (!customerAgentList[global.code]) {
				customerAgentList[global.code] = [];
			}
			customerAgentList[global.code].push(global.agent);
		}

		const newCustomerList = [...(await (await globalRef.get()).getSet('code'))];

		const tradeIvDnRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.currentMonthRange)
			.customers(newCustomerList)
			.includes('docType', ['IV', 'DN']);

		const tradeCNRef = FilteredDatabase.ref('customerTrades')
			.dateRange(this.currentMonthRange)
			.customers(newCustomerList)
			.includes('docType', ['CN']);

		this.tableFields = [
			{ key: 'company', sortable: true },
			{
				key: 'agent',
				sortable: true,
			},
			{
				key: 'month',
				sortable: true,
			},
			{
				key: 'first_month_sales',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
		];

		let pa = 0.5;
		let pb = 0;

		const tradeIvDnDD = await this._loadDimensionByFilters(
			tradeIvDnRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.5;

		const tradeCNDD = await this._loadDimensionByFilters(
			tradeCNRef,
			'customers',
			pa,
			pb,
			'amount',
		);

		const customers = Object.keys(customerAgentList);
		const agents: string[] = Object.values(customerAgentList);

		this.tableItems = tradeIvDnDD
			.filter((dd) => dd.sum !== 0)
			.map((dd, index) => {
				const customerIndex = customers.findIndex(
					(item) => item === dd.value[0],
				);
				const object: any = {
					first_month_sales:
						dd.sum - (this.includeCreditNote ? tradeCNDD[index].sum : 0),
					company: dd.text,
					agent: agents[customerIndex][0],
					month: this.formattedMonth,
				};
				return object;
			});
		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
