
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerSupplierTradeCard,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';

@Component({
	components: { TableFilterable, SelectFilter },
})
export default class CustomerRetentionTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public selectedAbove: number = 1;
	public selectedBelow: number = 12;
	public selectedMonth: number = moment().month();

	public get monthList() {
		const list: object[] = [];
		for (let i = 1; i <= 24; i++) {
      if (i === 1) {
        list.push({
          name: i + ' month',
          value: i,
        });
      } else {
        list.push(
            {
              name: i + ' months',
              value: i,
            },
        );
      }
		}
		return list;
	}
	public get monthTextList() {
		const list: ValueText[] = [];
		for (let i = 0; i < 12; i++) {
			const object = { value: i, text: '' };
			object.text = moment().month(i).format('MMM');
			list.push(object);
		}
		return list;
	}

	public get aboveSelection() {
		return this.selectedAbove;
	}

	public get belowSelection() {
		return this.selectedBelow;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get dateFormatted() {
		return [this.currentMonth];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonth() {
		return moment()
			.year(this.currentYear)
			.month(this.selectedMonth)
			.endOf('month')
			.valueOf();
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			aboveSelection,
			belowSelection,
			selectedMonth,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
			aboveSelection,
			belowSelection,
			selectedMonth,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('customerTrades')
			.dateAsOf(this.currentMonth)
			.includes('docType', ['IV', 'DN']);

		const tradeCards: CustomerSupplierTradeCard[] = await (
			await tradeRef.get()
		).getCards();

		const customerAgentList = {};

		for (const ivdn of tradeCards) {
			if (!customerAgentList[ivdn.code]) {
				customerAgentList[ivdn.code] = [];
			}
			customerAgentList[ivdn.code].push(ivdn);
		}

		const codeList: string[] = Object.keys(customerAgentList);
		const detailList: any[][] = Object.values(customerAgentList);

		const result: Array<{
			company_code: string;
			company: string;
			agent: string;
		}> = [];


		detailList.forEach(
			(item, index) => {
				const lastDeal = item.reduce((a, b) => (a.date > b.date ? a : b));

				const agent = lastDeal.agent;
				const date = lastDeal.date;
    const last_amount = lastDeal.amount;
    const last_code = lastDeal.code;

    const mmt = moment(date);

				const diff = moment(this.currentMonth).diff(mmt, 'months');

				const object = {
					company_code: '',
					company: '',
					agent: '',
          age: 0,
          last_invoice: '',
          last_amount: 0,
				};

				if (diff >= this.aboveSelection && diff <= this.belowSelection) {
					object.agent = agent;
     object.age = Math.ceil(moment.duration(moment().valueOf() - date).asDays());
     object.last_invoice = moment(date).format('DD/MM/YYYY');
     object.last_amount = last_amount;
					object.company_code = codeList[index];
					object.company = gdbx.customerNames[codeList[index]];

					result.push(object);
				}
			},
		);

		// const tradeRef = FilteredDatabase.ref('customerTrades')
		// 	.year(this.currentYear)
		// 	.customers(newCustomerList);

		this.tableFields = [
			{ key: 'company', sortable: true },
			{
				key: 'agent',
				sortable: true,
			},
      {
        key: 'age',
        sortable: true,
      },
      {
        key: 'last_invoice',
        sortable: true,
      },
      {
        key: 'last_amount',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'outstanding',
        sortable: true,
      },
		];

		this.tableItems = result;
		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
